<template>
  <div>
    <el-dialog :visible.sync="addVisible" :title="title" width="600px">
      <el-form ref="form" :model="form" label-width="auto">
        <el-form-item label="一级分解:">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="二级分解:">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="三级分解:">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="四级分解:">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="五级分解:">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="责任单位:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="责任班组:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="责任部门:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="责任人:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="活动类别:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="输入输出类别:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="责任部门:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="输入输出接口:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="周期:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">保 存</el-button>
        <el-button @click="addVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addVisible: this.visible,
      form: {},
      options:[],
    };
  },
  watch: {
    addVisible(newValue) {
      this.$emit("update:visible", newValue);
    },
    visible(newValue) {
      this.addVisible = newValue;
    },
  },
  methods:{
    submit(){
      this.$emit('submit',this.form)
    }
  }
};
</script>

<style lang="less" scoped>
.el-form-item {
  margin-bottom: 10px !important;
}
</style>