<template>
  <div class="container">
    <div class="leftBox">
      <div class="screen" @click="isMore = !isMore">{{ displayText }}</div>
      <div class="fileBox" v-if="isMore">
        <el-form>
          <el-form-item>
            <el-select v-model="form.name" placeholder="请选择公司" style="width: 100%">
              <el-option
                v-for="item in companyList"
                :key="item.id"
                :label="item.companyName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-row :gutter="2">
              <el-col :span="19">
                <el-input placeholder="请输入文件名称"></el-input>
              </el-col>
              <el-col :span="5">
                <el-button type="primary">筛选</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <div class="fileList">
          <el-tree
            :data="data"
            :props="defaultProps"
            highlight-current
            default-expand-all
            :expand-on-click-node="false"
            ref="tree"
          >
            <div class="custom-tree-node" slot-scope="{ node,data }">
              <img
                v-if="data.children"
                width="18px"
                src="../../../assets/optimizeImgs/folder.png"
                alt
              />
              <i class="el-icon-document" v-else></i>
              <span>{{ node.label }}</span>
            </div>
          </el-tree>
        </div>
      </div>
      <div class="fileBox" v-else>
        <el-form :model="form" label-width="auto">
          <el-form-item label="文件类型">
            <el-select v-model="form.name" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文件名称">
            <el-select v-model="form.name" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分解内容">
            <el-select v-model="form.name" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="责任单位">
            <el-select v-model="form.name" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="责任部门">
            <el-select v-model="form.name" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="责任班组">
            <el-select v-model="form.name" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="责任人">
            <el-select v-model="form.name" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动类别">
            <el-select v-model="form.name" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="输入输出类别">
            <el-select v-model="form.name" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="输入输出周期">
            <el-select v-model="form.name" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="输入输出过程">
            <el-select v-model="form.name" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="相关文件">
            <el-select v-model="form.name" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="相关记录">
            <el-select v-model="form.name" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
            <el-checkbox v-model="form.checked">模糊搜索</el-checkbox>
        </el-form>
      </div>
      <div class="btnBox" v-if="!isMore">
        <el-button type="primary">筛选</el-button>
        <el-button type="info" plain>清空</el-button>
      </div>
    </div>
    <div class="rightBox">
      <div class="queryBox">
        <el-form :inline="true" :model="form" label-width="auto">
          <el-form-item label="优化名称">
            <el-select v-model="form.name" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-circle-plus" @click="handleAdd">新增分解</el-button>
            <el-dropdown trigger="click">
              <el-button type="info">
                <img src="../../../assets/optimizeImgs/execl.png" alt />
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>导入文件</el-dropdown-item>
                <el-dropdown-item>导入文件</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <img src="../../../assets/optimizeImgs/delete.png" alt />
            <img src="../../../assets/optimizeImgs/edit.png" alt style="margin-left: 5px" />
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData" border style="width: 100%" height="calc(100% - 102px)">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="compName" label="程序文件"></el-table-column>
        <el-table-column label="活动分解">
          <el-table-column label="一级分解"></el-table-column>
          <el-table-column label="二级分解"></el-table-column>
          <el-table-column label="三级分解"></el-table-column>
          <el-table-column label="四级分解"></el-table-column>
          <el-table-column label="五级分解"></el-table-column>
        </el-table-column>
        <el-table-column label="责任单位">
          <el-table-column label="公司"></el-table-column>
          <el-table-column label="部门"></el-table-column>
          <el-table-column label="班组"></el-table-column>
        </el-table-column>
        <el-table-column prop="deptName" label="责任人" width="130"></el-table-column>
        <el-table-column prop="userName" label="活动类型"></el-table-column>
        <el-table-column label="输入输出">
          <el-table-column label="类别"></el-table-column>
          <el-table-column label="接口"></el-table-column>
        </el-table-column>
        <el-table-column prop="userName" label="相关记录"></el-table-column>
        <el-table-column prop="userName" label="周期"></el-table-column>
        <el-table-column prop="userName" label="标准"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <AddResolveDialog :visible.sync="dialogResolve" title="新增分解" @submit="submit"></AddResolveDialog>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from "../../../api/http";
import AddResolveDialog from "../components/AddResolveDialog.vue";
export default {
  components: {
    AddResolveDialog,
  },
  data() {
    return {
      options: [],
      data: [
        {
          label: "全部文件",
          children: [
            {
              label: "文件 AAAAAAAA",
            },
            {
              label: "文件 AAAAAAAA",
            },
            {
              label: "文件 AAAAAAAA",
            },
            {
              label: "文件 AAAAAAAA",
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      form: {},
      page: 1,
      limit: 10,
      total: 0,
      tableData: [],
      isMore: true,
      companyList:[],
      dialogResolve: false,
    };
  },
  computed: {
    displayText() {
      return this.isMore ? '更多筛选 >>>' : '<<< 返回文件';
    }
  },
  created() {
    this.getCompanyList();
  },
  mounted() {},
  methods: {
    // 获取公司列表
    getCompanyList() {
      get('/api/Company/GetAll').then(res =>{
        if(res.code == 200){
          this.companyList = res.data;
        }
      })
    },
    // 新增分解按钮
    handleAdd(){
      this.dialogResolve = true;
    },
    submit(e){
      console.log(e);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/optimizeTable.less";
.container {
  width: 100%;
  height: 100%;
  background: #e7e6eb;
  display: flex;
  .leftBox {
    width: 272px;
    height: 100%;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #cfcfcf;
    padding-bottom: 62px;
    box-sizing: border-box;
    margin-right: 6px;
    .screen {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 12px;
      color: #5a8bff;
      padding: 24px 0 14px 12px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .fileBox {
      height: calc(100% - 54px);
      background: #f9f9f9;
      border-radius: 2px;
      padding: 16px 12px;
      box-sizing: border-box;
      .el-form-item {
        margin-bottom: 4px;
      }
      ::v-deep .el-input__inner {
        height: 40px !important;
      }
      .fileList {
        margin-top: 12px;
        height: calc(100% - 100px);
        overflow-y: auto;
        .el-tree {
          position: relative;
          background-color: #f9f9f9;
          ::v-deep .el-tree-node__expand-icon {
            font-size: 18px;
            vertical-align: middle;
          }
          ::v-deep .el-tree-node__content {
            display: flex;
            align-items: center !important;
          }
          .custom-tree-node {
            display: flex;
            align-items: center;
            img {
              vertical-align: middle;
            }
            span {
              vertical-align: middle;
              margin-left: 5px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              font-size: 14px;
              color: #000000;
            }
          }
          ::v-deep .el-tree-node:before {
            position: absolute;
            top: 26px;
            left: 34px;
            height: calc(100% - 42px);
            border-left: 1px solid #dbdbdb;
            content: "";
          }
          ::v-deep .el-tree-node__children {
            position: relative;
            .el-tree-node__content:after {
              position: absolute;
              left: 34px;
              width: 10px;
              border-top: 1px solid #dbdbdb;
              content: "";
            }
            .custom-tree-node {
              span {
                font-weight: 400;
              }
            }
          }
        }
      }
    }
    .btnBox{
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
  }
  .rightBox {
    width: calc(100% - 278px);
    height: 100%;
    background: #ffffff;
    .queryBox {
      padding-left: 22px;
      padding-top: 5px;
      .el-dropdown{
        margin: 0 5px;
        ::v-deep .el-button--info {
          background: #F3F3FB;
          border-color: #D8E1EF;
          height: 34px !important;
        }
        ::v-deep .el-icon--right{
          color: #D0D0EE !important;
        }
      }
      img {
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }
      .el-form-item {
        margin-bottom: 6px;
      }
    }
  }
}
</style>